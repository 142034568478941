import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/action/user";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./login.css";
import loginLeftImg from "../../images/login-left-img.png";
import loginLogoTop from "../../images/login-logo-top.png";
import loginLogoBottom from "../../images/login-logo-bottom.png";

function App() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const loginSuccess = useSelector((state) => state.user.success);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loginHandler = async () => {
    try {
      const loggedIn = await dispatch(login(username, password));
      if (loggedIn) {
        navigate("/overview");
      } else {
        throw new Error("Login failed");
      }
    } catch (error) {
      toast.error("Login failed,Please Try After Sometime....");
    }
  };

  return (
    <div className="login-container">
      <ToastContainer />
      <div className="login-left">
        {/* <img className="login-left-img" src={loginLeftImg} alt="" /> */}
        <div className="login-top-logo-container"><img src={loginLogoTop} alt=""  className="login-top-logo"/></div>
        <div className="login-bottom-logo-container"><img src={loginLogoBottom} alt="" className="login-bottom-logo" /></div>
      </div>
      <div className="login-right">
        <div className="login-box">
        <span className="sign-in title">Sign In</span>
        <div className="login-box-in">
        <div className="title-div">
            <span className="subtitle">Please enter your details</span>
          </div>
          <div className="username-container">
            <label>Username</label>
            <input
              className="username textfield"
              type="text"
              name="username"
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="password-container">
            <label>Password</label>
            <input
              className="password textfield"
              type="password"
              name="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {/* <div className="forgot-container">
            <div className="remember-me-container">
              <input
                type="checkbox"
                className="forgot-checkbox"
                name="subscribe"
                id="subscribe"
              />
              <span>Remember Me</span>
            </div>
            <button className="forgot-password-btn">Forgot Password?</button>
          </div> */}
          <button className="sign-in-btn" onClick={loginHandler}>
            Sign In
          </button>
        </div>

        </div>
      </div>
    </div>
  );
}

export default App;
