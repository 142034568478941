import {
    fetchfilteredReportNoImgDataRequest,
    fetchfilteredReportNoImgDataSuccess,
    fetchfilteredReportNoImgDataFailure
  } from '../action/report';

const recentReport = {
    data: [],
    loading: false,
    error: null,
  };
  
  
  const filteredReportNoImgReducer = (state = recentReport, action) => {
    switch (action.type) {
      case fetchfilteredReportNoImgDataRequest().type:
        return { ...state, loading: true, error: null };
      case fetchfilteredReportNoImgDataSuccess().type:
        return { ...state, loading: false, data: action.payload };
      case fetchfilteredReportNoImgDataFailure().type:
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export default filteredReportNoImgReducer;