import { combineReducers } from "redux";
import userReducer from "./reducer/user";
import reportReducer from "./reducer/report";
import recentReportReducer from "./reducer/recenteport";
import childReducer from "./ChildSlice";
import imageReducer from "./ImageSlice";
import dailyReportReducer from "./reducer/dailyreports";
import clientReducer from "./reducer/client";
import filteredReportReducer from "./reducer/filteredreports";
import pdfReducer from "./reducer/pdfreducer";
import ticketReducer from './reducer/ticket';
import {clientLocationsReducer} from "./reducer/clientlocations";
import folderReducer from "./reducer/folder";
import filteredReportNoImgReducer from "./reducer/filteredReportsNoImg";
const rootReducer = combineReducers({
  report: reportReducer,
  folder:folderReducer,
  dailyReport: dailyReportReducer,
  recentReport: recentReportReducer,
  child: childReducer,
  image: imageReducer,
  user: userReducer,
  client: clientReducer,
  filtered: filteredReportReducer,
  filteredNoImg:filteredReportNoImgReducer,
  pdf: pdfReducer,
  ticket: ticketReducer,
  clientLocation: clientLocationsReducer,
});

export default rootReducer;
